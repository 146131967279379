import { Trans } from 'react-i18next';

import { PriorAuthorizationStatus } from '../../../graphql/generated/graphql';
import i18n from '../../../i18n';

import { AppealedDescription } from './status-descriptions/AppealedStatusDescriptions';
import { ApprovedDescription } from './status-descriptions/ApprovedStatusDescriptions';
import { CancelledDescription } from './status-descriptions/CancelledStatusDescriptions';
import { DeniedDescription } from './status-descriptions/DeniedStatusDescriptions';
import { RedeterminationDescription } from './status-descriptions/RedeterminationStatusDescriptions';

type PriorAuthStatusDescriptionProps = {
    openDecisionLetter?: () => Window | null;
    determiner?: string;
    status: PriorAuthorizationStatus;
    trackingNumber?: string | null;
    appealBeforeDate?: string | null;
    rationale?: string | null;
};

export const PriorAuthStatusDescription = ({
    openDecisionLetter,
    determiner,
    status,
    trackingNumber,
    appealBeforeDate,
    rationale,
}: PriorAuthStatusDescriptionProps) => {
    switch (status) {
        case 'NEW':
            return <Trans i18nKey="priorAuthorization.statusPage.new" />;
        case 'RECEIVED':
            return <Trans i18nKey="priorAuthorization.statusPage.received" />;
        case 'IN_REVIEW':
            return <Trans i18nKey="priorAuthorization.statusPage.inReview" />;
        case 'APPROVED':
            return <ApprovedDescription />;
        case 'DENIED':
            return (
                <DeniedDescription
                    openDecisionLetter={openDecisionLetter}
                    determiner={determiner}
                    rationale={rationale}
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                />
            );
        case 'REDETERMINATION':
            return (
                <RedeterminationDescription
                    openDecisionLetter={openDecisionLetter}
                    rationale={rationale}
                    trackingNumber={trackingNumber}
                />
            );
        case 'APPEALED':
            return (
                <AppealedDescription
                    rationale={rationale}
                    trackingNumber={trackingNumber}
                    openDecisionLetter={openDecisionLetter}
                />
            );
        case 'APPEAL_APPROVED':
            return <ApprovedDescription />;
        case 'APPEAL_DENIED':
            return (
                <DeniedDescription
                    rationale={rationale}
                    openDecisionLetter={openDecisionLetter}
                    determiner={determiner}
                    trackingNumber={trackingNumber}
                    appealBeforeDate={appealBeforeDate}
                />
            );
        case 'CANCELLED':
            return <CancelledDescription />;
        default:
            return null;
    }
};

export function getStatusTranslation(status: PriorAuthorizationStatus): string {
    switch (status) {
        case PriorAuthorizationStatus.New:
            return i18n.t('priorAuthorization.status.New');
        case PriorAuthorizationStatus.Received:
            return i18n.t('priorAuthorization.status.Received');
        case PriorAuthorizationStatus.InReview:
            return i18n.t('priorAuthorization.status.InReview');
        case PriorAuthorizationStatus.Approved:
            return i18n.t('priorAuthorization.status.Approved');
        case PriorAuthorizationStatus.Denied:
            return i18n.t('priorAuthorization.status.Denied');
        case PriorAuthorizationStatus.Redetermination:
            return i18n.t('priorAuthorization.status.Redetermination');
        case PriorAuthorizationStatus.Appealed:
            return i18n.t('priorAuthorization.status.Appealed');
        case PriorAuthorizationStatus.AppealDenied:
            return i18n.t('priorAuthorization.status.AppealDenied');
        case PriorAuthorizationStatus.Cancelled:
            return i18n.t('priorAuthorization.status.Cancelled');
        default:
            return '';
    }
}
