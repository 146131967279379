import {
    Body1,
    FilledCard,
    Flex,
    Heading2,
    Stack,
    TextButton,
} from '@phx/design-system';
import { CoverageCard } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCoverageCardData } from '../../../hooks/use-coverage-card-data';
import { getAbsoluteRoute } from '../../../util';

// Note: This is similar to the ShareWithPharmacy component but approved prior auth prescriptions don't have an offer to pass in to grab coverage details.
export const PriorAuthCoverageCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const coverages = useCoverageCardData();

    return (
        <FilledCard>
            <Stack gap="sm">
                <Flex justify="space-between" align="center">
                    <Heading2>
                        {t('orderConfirmation.coverageCard.subHeader')}
                    </Heading2>
                    <TextButton
                        onClick={() =>
                            navigate(getAbsoluteRoute('profile.benefit'))
                        }
                    >
                        {t('orderConfirmation.coverageCard.viewDetails')}
                    </TextButton>
                </Flex>
                <Body1>
                    {t(
                        'orderConfirmation.coverageCard.mailOrderUrlDescription'
                    )}
                </Body1>
                <CoverageCard {...coverages[0]} />
            </Stack>
        </FilledCard>
    );
};
