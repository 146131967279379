import { Grid, GridCol, Heading2, Stack, Subtitle2 } from '@phx/design-system';
import {
    ComparePricesIllustration,
    ConsumersIllustration,
    DocumentsIllustration,
    IllustrationCard,
    PharmacyIllustration,
    PillsIllustration,
    PrescriptionIllustration,
    ReportsIllustration,
    ShieldIllustration,
} from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../../components/utility/ConditionalComponent';
import { useFlags } from '../../../../hooks/use-flags';
import { usePatientContext } from '../../../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../../../util';

export const FaqSection = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);
    const { patient } = usePatientContext();
    const navigate = useNavigate();
    const { helpCenterMedicationEnabled } = useFlags();

    return (
        <Stack>
            <Heading2>{t('helpCenter.subheader.faq')}</Heading2>
            <Grid gutter="xs">
                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={PharmacyIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(getAbsoluteRoute('helpCenter.about'), {
                                state: { header: t('helpCenter.faq.about') },
                            })
                        }
                        color="primary"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.about')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>

                <ConditionalComponent condition={!!patient?.hasPbmPlan}>
                    <GridCol display="flex" span={6}>
                        <IllustrationCard
                            illustrationPosition="top"
                            variant="outlined"
                            Illustration={DocumentsIllustration}
                            illustrationSize="xs"
                            onClick={() =>
                                navigate(
                                    getAbsoluteRoute('helpCenter.employer'),
                                    {
                                        state: {
                                            header: t(
                                                'helpCenter.faq.employer'
                                            ),
                                        },
                                    }
                                )
                            }
                            color="saving"
                        >
                            <Subtitle2 ta="center">
                                {t('helpCenter.faq.employer')}
                            </Subtitle2>
                        </IllustrationCard>
                    </GridCol>
                </ConditionalComponent>

                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={ReportsIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(getAbsoluteRoute('helpCenter.settings'), {
                                state: { header: t('helpCenter.faq.settings') },
                            })
                        }
                        color="error"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.settings')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>

                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={ComparePricesIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(
                                getAbsoluteRoute('helpCenter.costsAndSavings'),
                                {
                                    state: {
                                        header: t(
                                            'helpCenter.faq.costsAndSavings'
                                        ),
                                    },
                                }
                            )
                        }
                        color="warning"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.costsAndSavings')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>

                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={PrescriptionIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(
                                getAbsoluteRoute(
                                    'helpCenter.prescriptionManagement'
                                ),
                                {
                                    state: {
                                        header: t(
                                            'helpCenter.faq.prescriptionManagement'
                                        ),
                                    },
                                }
                            )
                        }
                        color="info"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.prescriptionManagement')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>

                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={ConsumersIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(
                                getAbsoluteRoute('helpCenter.familyCare'),
                                {
                                    state: {
                                        header: t('helpCenter.faq.familyCare'),
                                    },
                                }
                            )
                        }
                        color="error"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.familyCare')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>

                <ConditionalComponent condition={helpCenterMedicationEnabled}>
                    <GridCol display="flex" span={6}>
                        <IllustrationCard
                            illustrationPosition="top"
                            variant="outlined"
                            Illustration={PillsIllustration}
                            illustrationSize="xs"
                            onClick={() =>
                                navigate(
                                    getAbsoluteRoute('helpCenter.medications'),
                                    {
                                        state: {
                                            header: t(
                                                'helpCenter.faq.medications'
                                            ),
                                        },
                                    }
                                )
                            }
                            color="info"
                        >
                            <Subtitle2 ta="center">
                                {t('helpCenter.faq.medications')}
                            </Subtitle2>
                        </IllustrationCard>
                    </GridCol>
                </ConditionalComponent>

                <GridCol display="flex" span={6}>
                    <IllustrationCard
                        illustrationPosition="top"
                        variant="outlined"
                        Illustration={ShieldIllustration}
                        illustrationSize="xs"
                        onClick={() =>
                            navigate(
                                getAbsoluteRoute('helpCenter.privacySecurity'),
                                {
                                    state: {
                                        header: t(
                                            'helpCenter.faq.privacySecurity'
                                        ),
                                    },
                                }
                            )
                        }
                        color="notice"
                    >
                        <Subtitle2 ta="center">
                            {t('helpCenter.faq.privacySecurity')}
                        </Subtitle2>
                    </IllustrationCard>
                </GridCol>
            </Grid>
        </Stack>
    );
};
