import {
    Anchor,
    Body1,
    Body2,
    Button,
    HorizontalDivider,
    Modal,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans, useTranslation } from 'react-i18next';

import { ConditionalComponent } from '../../utility/ConditionalComponent';

type DecisionDetailsModalType = {
    onOpenDecisionLetter?: () => void;
    isOpen: boolean;
    toggle: () => void;
    priorAuthStatus: string;
    validFrom?: string | null;
    validTo?: string | null;
    rationale?: string | null;
    tracking?: string | null;
};

export const DecisionDetailsModal = ({
    onOpenDecisionLetter,
    isOpen,
    toggle,
    priorAuthStatus,
    validFrom,
    validTo,
    rationale,
    tracking,
}: DecisionDetailsModalType) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('priorAuth.decisionDetails.modal.title', {
                priorAuthStatus,
            })}
            opened={isOpen}
            onClose={toggle}
        >
            <Stack gap="lg">
                <Stack gap="sm">
                    <ConditionalComponent condition={!!(validFrom && validTo)}>
                        <Stack gap="xxs">
                            <Subtitle2>
                                {t(
                                    'priorAuth.decisionDetails.modal.approvalTimeframe'
                                )}
                            </Subtitle2>
                            <Body1>
                                {t(
                                    'priorAuth.decisionDetails.modal.validDate',
                                    {
                                        validFrom,
                                        validTo,
                                    }
                                )}
                            </Body1>
                        </Stack>
                    </ConditionalComponent>
                    <ConditionalComponent condition={!!rationale}>
                        <Stack gap="xxs">
                            <Subtitle2>
                                {t('priorAuth.decisionDetails.modal.rationale')}
                            </Subtitle2>
                            <Body1>{rationale}</Body1>
                        </Stack>
                    </ConditionalComponent>
                    <ConditionalComponent condition={!!tracking}>
                        <Stack gap="xxs">
                            <Subtitle2>
                                {t(
                                    'priorAuth.decisionDetails.modal.trackingNumber'
                                )}
                            </Subtitle2>
                            <Body1>{tracking}</Body1>
                        </Stack>
                    </ConditionalComponent>
                    <HorizontalDivider />
                    <Body2>
                        <Trans
                            i18nKey={'priorAuth.decisionDetails.modal.footer'}
                        >
                            A decision letter was sent by mail to the patient
                            and faxed to the healthcare provider. For additional
                            questions regarding the decision,{' '}
                            <Anchor onClick={openTalkativeUI}>
                                contact us.
                            </Anchor>
                        </Trans>
                    </Body2>
                </Stack>
                <ConditionalComponent condition={!!onOpenDecisionLetter}>
                    <Button variant="outline" onClick={onOpenDecisionLetter}>
                        {t('priorAuth.decisionDetails.modal.button')}
                    </Button>
                </ConditionalComponent>
            </Stack>
        </Modal>
    );
};
