import {
    Body1,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    IconCheckCircle,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type FragmentsOf, getFragment } from '../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    GetOfferQuery,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { LocationInfo } from '../common/LocationInfo';
import { Pricing } from '../common/pricing';
import { Prescriber } from '../prescriber/Prescriber';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { NextSteps } from './components/NextSteps';
import { ShareWithPharmacy } from './components/ShareWithPharmacy';
import { YourPrescription } from './components/YourPrescription';

export type OrderConfirmationProps = {
    offer: NonNullable<FragmentsOf<GetOfferQuery['offer']>>;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const OrderConfirmation = ({
    offer,
    prescriberOrder,
}: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const fillLocation = getFragment(offer.pharmacyProvider);
    const controlledSubstanceCode = getFragment(
        prescriberOrder.authorizedMedication
    ).controlledSubstanceCode;

    // Temporary until VELA integration
    const isInstantRouting = false;
    const isRouted =
        false; /**Probably can remove this after using showinprogress */

    // TODO: Incorporate new pending status here
    const showInProgress = false;

    return (
        <Stack gap="xl" pb="md">
            {controlledSubstanceCode &&
            !isRouted &&
            !isInstantRouting &&
            showInProgress ? (
                <Stack gap="sm">
                    <Heading1>
                        {t('orderConfirmation.inProgress.header')}
                    </Heading1>
                    <Body1>
                        {t('orderConfirmation.inProgress.description1')}
                    </Body1>
                    <Subtitle2>
                        {t('orderConfirmation.inProgress.description2')}
                    </Subtitle2>
                </Stack>
            ) : (
                <ShoppingHeader>
                    <Stack gap="md">
                        <Stack align="center" gap="xxs">
                            <IconCheckCircle
                                filled
                                variant="rounded"
                                width="2em"
                                height="2em"
                                color="success"
                            />
                            <Heading1>{t('orderConfirmation.header')}</Heading1>
                        </Stack>
                        <NextSteps location={fillLocation} />
                    </Stack>
                </ShoppingHeader>
            )}

            <HorizontalDivider variant="full" />

            <Stack gap="sm">
                <Heading2>{t('orderConfirmation.pharmacy.subHeader')}</Heading2>
                <ConditionalComponent condition={!!fillLocation}>
                    <LocationInfo location={fillLocation} />
                </ConditionalComponent>
            </Stack>
            <ConditionalComponent
                condition={
                    isInstantRouting ||
                    isRouted ||
                    !controlledSubstanceCode ||
                    !showInProgress
                }
            >
                <ShareWithPharmacy
                    offer={offer}
                    prescriberOrder={prescriberOrder}
                />
            </ConditionalComponent>
            <YourPrescription
                controlledSubstanceCode={controlledSubstanceCode}
                isInstantRouting={isInstantRouting}
                offer={offer}
                prescriberOrder={prescriberOrder}
            />

            <Pricing offer={offer} />

            <Stack gap="sm">
                <Heading2>
                    {t('orderConfirmation.prescriber.subHeader')}
                </Heading2>
                <Prescriber
                    prescriber={getFragment(prescriberOrder.prescriber)}
                />
            </Stack>

            <HorizontalDivider variant="full" />

            <ContactUsWidget />

            <Button
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};

export default OrderConfirmation;
