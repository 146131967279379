import { NavBarProvider } from '@phx/design-system';

import { PatientProvider } from '../../contexts/patient/PatientProvider';
import { SidebarProvider } from '../../contexts/SidebarProvider';
import { useFlags } from '../../hooks/use-flags';
import Maintenance from '../maintenance/Maintenance';
import { AutoScrolltop } from '../utility/AutoScrolltop';

import { AppContent } from './AppContent';
import { AppSidebar } from './AppSidebar';

const AppRoot = () => {
    const { downForMaintenance } = useFlags();

    return (
        <>
            <PatientProvider>
                <NavBarProvider>
                    <SidebarProvider>
                        {downForMaintenance ? (
                            <Maintenance />
                        ) : (
                            <>
                                <AppContent />
                                <AppSidebar />
                            </>
                        )}
                    </SidebarProvider>
                </NavBarProvider>
            </PatientProvider>
            <AutoScrolltop />
        </>
    );
};

export default AppRoot;
