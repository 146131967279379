import { useQuery } from '@apollo/client';
import {
    Body1,
    ElevatedCard,
    Flex,
    FullBleed,
    Heading1,
    IconFilter,
    Stack,
    VerticalDivider,
    useConditionalBackOverride,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MedicineCabinetPlaceholder } from '../../components/cabinet/MedicineCabinetPlaceholder';
import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { GetBasicPrescriberOrdersDocument } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    type PatientSelectorOption,
    UNCLAIMED,
    VIEW_ALL,
    usePatientSelectorOptions,
} from '../../hooks/use-patient-selector-options';

import { MedCabDetailCard } from './components/MedCabDetailCard';
import { PatientSelector } from './components/PatientSelector';
import { PrescriptionHistoryLink } from './components/PrescriptionHistoryLink';
import { SpendSummaryLink } from './components/SpendSummaryLink';

export const CabinetRoute = () => {
    const { t } = useTranslation();
    const {
        primaryPatientId,
        scopedPatientId,
        includeDependents,
        updatePatientIdScope,
    } = usePatientContext();
    const [onlyShowUnclaimedOrders, setOnlyShowUnclaimedOrders] =
        useState(false);
    const [isOpen, { open, close }] = useDisclosure();
    const {
        loading: patientsLoading,
        options,
        getOptionLabel,
    } = usePatientSelectorOptions();

    useConditionalBackOverride(close, isOpen);

    const { data, loading } = useQuery(GetBasicPrescriberOrdersDocument, {
        variables: {
            patientId: scopedPatientId ?? primaryPatientId,
        },
        errorPolicy: 'all',
    });

    const patientOptions: PatientSelectorOption[] = useMemo(() => {
        if (patientsLoading) return [];

        const hasUnclaimedPrescriptions =
            data?.patient?.unclaimedPrescriberOrdersConnection?.edges?.length;

        const unclaimed: PatientSelectorOption = {
            id: UNCLAIMED,
            name: t('patientSelector.needsVerification'),
            disabled: !hasUnclaimedPrescriptions,
        };

        const viewAll: PatientSelectorOption = {
            id: VIEW_ALL,
            name: t('patientSelector.allPrescriptions'),
        };

        const merged = [viewAll, ...options, unclaimed];

        return merged.map((o) => ({ ...o, name: getOptionLabel(o) }));
    }, [patientsLoading, data]);

    const handlePatientChange = ({ id }: PatientSelectorOption) => {
        setOnlyShowUnclaimedOrders(id === UNCLAIMED);

        const includeDependents = id === VIEW_ALL || id === UNCLAIMED;
        const updatedScope = includeDependents ? primaryPatientId : id;

        updatePatientIdScope(updatedScope, includeDependents);
    };

    const getInitalSelectedPatientId = () => {
        if (onlyShowUnclaimedOrders) return UNCLAIMED;
        if (includeDependents) return VIEW_ALL;
        return scopedPatientId;
    };

    const getSelectedPatientName = () => {
        const id = getInitalSelectedPatientId();
        const option = patientOptions.find((o) => o.id === id);
        return option?.name ?? t('patientSelector.allPrescriptions');
    };

    if (isOpen) {
        return (
            <PatientSelector
                initialSelectedId={getInitalSelectedPatientId()}
                onClose={close}
                onChange={handlePatientChange}
                options={patientOptions}
                loading={patientsLoading}
            />
        );
    }

    return (
        <Stack gap="lg">
            <ShoppingHeader>
                <Heading1>{t('cabinet.heading')}</Heading1>
            </ShoppingHeader>
            <FullBleed breakpoint="md" color="gray">
                <Stack gap="lg">
                    <ElevatedCard
                        onClick={open}
                        data-testid="patient-selector-button-card"
                    >
                        <Flex justify="space-between" align="center">
                            <Body1 bold color="primary">
                                {getSelectedPatientName()}
                            </Body1>
                            <Flex gap="xs">
                                <VerticalDivider />
                                <IconFilter color="primary" />
                            </Flex>
                        </Flex>
                    </ElevatedCard>
                    {loading && !data?.patient ? (
                        <MedicineCabinetPlaceholder variant="elevated" />
                    ) : null}
                    {data?.patient ? (
                        <MedCabDetailCard
                            patient={data.patient}
                            patientId={scopedPatientId ?? primaryPatientId}
                            onlyShowUnclaimedOrders={onlyShowUnclaimedOrders}
                        />
                    ) : null}
                </Stack>
            </FullBleed>
            <Stack gap="md">
                {data?.patient ? (
                    <PrescriptionHistoryLink patient={data.patient} />
                ) : null}
                <SpendSummaryLink />
            </Stack>
        </Stack>
    );
};
