import {
    Body1,
    Box,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    IconFileOpen,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { PharmacyInfo } from '@phx/myphx-lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
    PriorAuthorizationInfoFragment,
} from '../../graphql/generated/graphql';
import { PriorAuthorizationStatus as PriorAuthorizationStatusType } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { usePatientContext } from '../../hooks/use-patient-context';
import { formatToLocalizedDate, getAbsoluteRoute } from '../../util';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { PatientProductDetails } from '../preview-details/components/PatientProductDetails';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { DecisionDetailsModal } from './components/DecisionDetailsModal';
import { PriorAuthCoverageCard } from './components/PriorAuthCoverageCard';
import {
    PriorAuthStatusDescription,
    getStatusTranslation,
} from './components/PriorAuthStatusDescriptions';
import { PriorAuthTimeline } from './components/PriorAuthTimeline';
type PriorAuthorizationStatusProps = {
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
    priorAuthInfo: PriorAuthorizationInfoFragment;
    location?: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
};

export const PriorAuthorizationStatus = ({
    prescriberOrder,
    priorAuthInfo,
    location,
}: PriorAuthorizationStatusProps) => {
    const { priorAuthorizationEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [opened, { toggle }] = useDisclosure();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;

    if (!priorAuthorizationEnabled) return null;

    const patient = getFragment(prescriberOrder.patient);
    const product = prescriberOrder.productDescription;
    const quantity = prescriberOrder.quantity;

    const isApproved =
        priorAuthInfo.status === PriorAuthorizationStatusType.Approved ||
        priorAuthInfo.status === PriorAuthorizationStatusType.AppealApproved;

    const openDecisionLetter = useMemo(() => {
        const documentUri = priorAuthInfo.documents[0]?.documentUri;
        return documentUri
            ? () => window.open(documentUri, '_blank')
            : undefined;
    }, [priorAuthInfo.documents]);

    const determiner = openDecisionLetter
        ? t('priorAuthorization.determiner.this')
        : t('priorAuthorization.determiner.a');

    const trackingNumber = priorAuthInfo.externalThirdPartyId;
    const validToDate = getFragment(priorAuthInfo.effectiveDates).effectiveTo;
    const validFromDate = getFragment(
        priorAuthInfo.effectiveDates
    ).effectiveFrom;

    // TODO: This should be coming from the graph when available
    // Note: Denial date + 179 days is the time allowed to appeal decision
    let appealDeadline: string | null = null;

    if (priorAuthInfo.status.includes(PriorAuthorizationStatusType.Denied)) {
        const denialDate = new Date(priorAuthInfo.updatedAt);
        denialDate.setDate(denialDate.getDate() + 179);
        appealDeadline = formatToLocalizedDate(denialDate, {
            dateStyle: 'medium',
        });
    }

    return (
        <Stack>
            <PatientProductDetails
                patientName={patient.fullName}
                productDescription={product}
                quantity={quantity}
                prescriberOrder={prescriberOrder}
            />
            <HorizontalDivider />
            <Stack gap="xl">
                <Stack gap="sm">
                    <Heading1>
                        {t('priorAuthorization.statusPage.currentStatus')}
                        {getStatusTranslation(priorAuthInfo.status)}
                    </Heading1>
                    <PriorAuthStatusDescription
                        openDecisionLetter={openDecisionLetter}
                        trackingNumber={trackingNumber}
                        appealBeforeDate={appealDeadline}
                        determiner={determiner}
                        rationale={priorAuthInfo.determinationRationale}
                        status={priorAuthInfo.status}
                    />
                </Stack>
                <DecisionDetailsModal
                    isOpen={opened}
                    toggle={toggle}
                    priorAuthStatus={getStatusTranslation(priorAuthInfo.status)}
                    validFrom={validFromDate}
                    validTo={validToDate}
                    rationale={priorAuthInfo.determinationRationale}
                    tracking={trackingNumber}
                    onOpenDecisionLetter={openDecisionLetter}
                />
                <ConditionalComponent condition={isApproved}>
                    <Stack gap="sm">
                        <PriorAuthCoverageCard />
                        <ConditionalComponent condition={!!openDecisionLetter}>
                            <Box>
                                <TextButton
                                    onClick={toggle}
                                    LeftIcon={IconFileOpen}
                                >
                                    {t(
                                        'priorAuthorization.statusPage.viewDecisionDetails'
                                    )}
                                </TextButton>
                            </Box>
                        </ConditionalComponent>
                        <Body1>
                            {t(
                                'priorAuthorization.statusPage.eligibleForBenefit'
                            )}
                        </Body1>
                    </Stack>
                </ConditionalComponent>
                <PriorAuthTimeline priorAuthInfo={priorAuthInfo} />

                {location ? (
                    <Stack gap="sm">
                        <Stack gap="xxs">
                            <Heading2>{t('previewDetails.heading')}</Heading2>
                            <Body1>
                                {t(
                                    'priorAuthorization.statusPage.sentPrescription'
                                )}
                            </Body1>
                        </Stack>
                        <PharmacyInfo
                            name={location.name}
                            phone={location.phoneNumber}
                            favorite={checkIsFavorite(location.id)}
                            toggleFavorite={() => toggleFavorite(location.id)}
                            shippingTime={
                                priorAuthInfo.status.includes('Approved')
                                    ? '2-4'
                                    : undefined
                            }
                        />
                    </Stack>
                ) : null}
            </Stack>

            <HorizontalDivider />
            <ContactUsWidget />
            <Button
                onClick={() => {
                    navigate(getAbsoluteRoute('home'));
                }}
            >
                {t('common.button.returnToHome')}
            </Button>
        </Stack>
    );
};
