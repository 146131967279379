import { Body1, Heading2, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import type { OfferFragment } from '../../../types';
import { LocationInfo } from '../../common/LocationInfo';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

export const PharmacyProviderDetails = ({
    offer,
}: {
    offer: OfferFragment;
}) => {
    const { t } = useTranslation();

    const location = getFragment(offer.pharmacyProvider);
    let description;
    switch (location.__typename) {
        case 'MailOrderProvider':
            if (location.url) {
                description = t('previewDetails.description.mailOrderWebsite');
            } else if (location.phoneNumber) {
                description = t('previewDetails.description.mailOrderPhone');
            }
            break;
        default:
            description = t('previewDetails.description.pickUp');
            break;
    }

    const controlledSubstanceCode = getFragment(
        offer.medication
    ).controlledSubstanceCode;

    //temp
    const isInstantRouting = false;

    const displayRegulatoryInfo =
        !isInstantRouting && !!controlledSubstanceCode;

    return (
        <Stack gap="sm">
            <Heading2>{t('previewDetails.heading')}</Heading2>
            {offer.prescriberOrder && !controlledSubstanceCode ? (
                <Body1>{description}</Body1>
            ) : null}
            <ConditionalComponent condition={!!location}>
                <LocationInfo location={location} />
            </ConditionalComponent>
            <ConditionalComponent condition={displayRegulatoryInfo}>
                <Body1>{t('previewDetails.controlledSubstances')}</Body1>
            </ConditionalComponent>
        </Stack>
    );
};
