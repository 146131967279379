import { Anchor, Body1, Box, Heading2, Image, Stack } from '@phx/design-system';
import { UnderMaintenanceIllustration } from '@phx/myphx-lib';
import { openTalkativeUI } from '@phx/talkative';
import { Trans, useTranslation } from 'react-i18next';

const Maintenance = () => {
    const { t } = useTranslation();

    return (
        <Box p="md">
            <Image
                src="/static/img/myprescryptive-logo.png"
                alt="under-maintenance"
                width="128"
                height="48"
                fit="contain"
            />
            <Stack gap="md" justify="center" align="center" p="md">
                <UnderMaintenanceIllustration />
                <Stack gap="sm" justify="center" align="center" ta="center">
                    <Heading2>{t('maintenance.generic.title')}</Heading2>
                    <Body1>{t('maintenance.generic.subtitle')}</Body1>
                    <Body1>
                        <Trans i18nKey="maintenance.generic.supportText">
                            {'If you have any inquiries, '}
                            <Anchor onClick={openTalkativeUI}>
                                {'contact us'}
                            </Anchor>
                        </Trans>
                    </Body1>
                </Stack>
            </Stack>
        </Box>
    );
};
export default Maintenance;
