import { Anchor, Body1 } from '@phx/design-system';
import { openTalkativeUI } from '@phx/talkative';
import { Trans } from 'react-i18next';

export const CancelledDescription = () => (
    <Body1>
        <Trans
            i18nKey="priorAuthorization.statusPage.cancelled"
            components={{
                2: <Anchor onClick={openTalkativeUI} />,
            }}
        />
    </Body1>
);
