import {
    Body1,
    Button,
    IconBolt,
    Modal,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

// TODO: Ideally this modal doesn't pop up again when user navigates BACK from select pricing options
export const InstantRoutingModal = () => {
    const { t } = useTranslation();
    const [opened, { close }] = useDisclosure(true);

    return (
        <Modal
            title={t('instantRouting.modal.header')}
            titleColor="info"
            TitleIcon={<IconBolt color="info" variant="rounded" filled />}
            opened={opened}
            onClose={close}
        >
            <Stack gap="lg">
                <Stack gap="sm">
                    <Subtitle2>{t('instantRouting.modal.subtitle')}</Subtitle2>
                    <Body1>{t('instantRouting.modal.description')}</Body1>
                </Stack>
                <Button variant="outline" onClick={close}>
                    {t('instantRouting.modal.pickPharmacy')}
                </Button>
            </Stack>
        </Modal>
    );
};
