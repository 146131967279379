import { Body1, PhoneNumber, Stack, Subtitle1 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { PrescriberInfoFragment } from '../../graphql/generated/graphql';
import { ConditionalComponent } from '../utility/ConditionalComponent';

type PrescriberProps = {
    prescriber: PrescriberInfoFragment;
};

export const Prescriber = ({ prescriber }: PrescriberProps) => {
    const { t } = useTranslation();

    return (
        <ConditionalComponent condition={!!prescriber}>
            <Stack gap="sm">
                <Body1>{t('prescriber.contact')}</Body1>
                <Subtitle1 data-testid="prescriber-full-name">
                    {prescriber.fullNameWithTitle}
                </Subtitle1>
                {prescriber.phoneNumber && (
                    <PhoneNumber phone={prescriber.phoneNumber} />
                )}
            </Stack>
        </ConditionalComponent>
    );
};
