import {
    Body1,
    Body2,
    Box,
    Flex,
    HorizontalDivider,
    IconChevronRight,
    OutlinedCard,
    Stack,
    Subtitle1,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import { getLocationInfoFragment } from '../../../graphql/client/helpers/get-location-fragment';
import {
    type DigitallyReceivedPrescriberOrderInfoFragment,
    PrescriberOrderStatus,
    type PriceQuoteInfoFragment,
    PriorAuthorizationStatus,
} from '../../../graphql/generated/graphql';
import { useFlags } from '../../../hooks/use-flags';
import {
    formatToLocalizedDate,
    getAbsoluteRoute,
    titleCase,
} from '../../../util';

export type LastOrderActivityProps = {
    priceQuote?: PriceQuoteInfoFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const LastOrderActivity = ({
    priceQuote,
    prescriberOrder,
}: LastOrderActivityProps) => {
    const { priorAuthorizationEnabled } = useFlags();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const pharmacy = priceQuote
        ? getLocationInfoFragment(priceQuote?.location)
        : undefined;
    const pharmacyName = getFragment(pharmacy)?.name;

    const hasOrderDate = !!prescriberOrder.latestSelectedPriceQuoteTimestamp;

    const navigateToOrderConfirmation = useCallback(() => {
        navigate(
            getAbsoluteRoute('orders.prescriberOrderId.confirmation', {
                prescriberOrderId: prescriberOrder.id,
            })
        );
    }, [navigate]);

    const navigateToPriorAuthStatus = useCallback(() => {
        navigate(
            getAbsoluteRoute('orders.prescriberOrderId.priorAuthorization', {
                prescriberOrderId: prescriberOrder.id,
            })
        );
    }, [navigate]);

    const applicablePriorAuthorization = getFragment(
        prescriberOrder.applicablePriorAuthorization
    );

    let status = '';
    let priorAuthLinkText = t('orderDetails.trackStatus');
    if (applicablePriorAuthorization?.status) {
        switch (applicablePriorAuthorization?.status) {
            case PriorAuthorizationStatus.Approved:
            case PriorAuthorizationStatus.Denied:
            case PriorAuthorizationStatus.AppealApproved:
            case PriorAuthorizationStatus.AppealDenied:
                status = t('priorAuthorization.status.complete');
                priorAuthLinkText = t('orderDetails.viewDetails');
                break;
            case PriorAuthorizationStatus.Cancelled:
                status = t('priorAuthorization.status.cancelled');
                priorAuthLinkText = t('orderDetails.viewDetails');
                break;
            default:
                status = t('priorAuthorization.status.required');
                priorAuthLinkText = t('orderDetails.trackStatus');
                break;
        }
    }

    const showPriorAuthLastActivity =
        !!applicablePriorAuthorization && priorAuthorizationEnabled;
    const assignedPharmacyProvider =
        applicablePriorAuthorization?.assignedPharmacyProvider;
    const isPrescriberOrderCancelled =
        prescriberOrder.status === PrescriberOrderStatus.Cancelled;

    // Temporary until VELA integration
    const isRouted = undefined;

    // Note: Instead of an LD flag, using a false variable to keep the In Progress section accessible until it's ready. Not sure how the data will come in anyways so using a flag will still require some code changes to incorporate In Progress section.
    const showInProgress = false;

    const lastActivityStatus =
        isRouted === false && showInProgress
            ? t('orderDetails.inProgress')
            : isPrescriberOrderCancelled
              ? t('orderDetails.cancelled')
              : t('orderDetails.sentToPharmacy');
    const sentToPharmacyName = isPrescriberOrderCancelled
        ? t('orderDetails.cancelledDescription')
        : pharmacyName
          ? titleCase(pharmacyName)
          : null;

    return (
        <ConditionalComponent
            condition={
                !!prescriberOrder.latestSelectedOffer ||
                !!prescriberOrder.latestSelectedPriceQuote ||
                showPriorAuthLastActivity
            }
        >
            <OutlinedCard>
                <Stack gap="xs">
                    <Subtitle1>{t('orderDetails.lastActivity')}</Subtitle1>
                    <HorizontalDivider />
                    <ConditionalComponent condition={showPriorAuthLastActivity}>
                        <Stack gap="xxs">
                            <Flex justify="space-between">
                                <Subtitle2>
                                    {t('orderDetails.paStatus', { status })}
                                </Subtitle2>
                                {applicablePriorAuthorization?.updatedAt ? (
                                    <Body2>
                                        {formatToLocalizedDate(
                                            new Date(
                                                applicablePriorAuthorization?.updatedAt
                                            ),
                                            { dateStyle: 'medium' },
                                            i18n.language
                                        )}
                                    </Body2>
                                ) : null}
                            </Flex>
                            <Box>
                                <TextButton
                                    RightIcon={IconChevronRight}
                                    onClick={navigateToPriorAuthStatus}
                                >
                                    {priorAuthLinkText}
                                </TextButton>
                            </Box>
                        </Stack>
                        <ConditionalComponent
                            condition={!!assignedPharmacyProvider}
                        >
                            <Stack gap="xs">
                                <Flex justify="space-between">
                                    <Subtitle2>
                                        {t('orderDetails.sentToPharmacy')}
                                    </Subtitle2>
                                    {applicablePriorAuthorization?.createdAt ? (
                                        <Body2>
                                            {formatToLocalizedDate(
                                                new Date(
                                                    applicablePriorAuthorization?.createdAt
                                                ),
                                                { dateStyle: 'medium' },
                                                i18n.language
                                            )}
                                        </Body2>
                                    ) : null}
                                </Flex>
                                <Body1>
                                    {
                                        getFragment(assignedPharmacyProvider)
                                            ?.name
                                    }
                                </Body1>
                            </Stack>
                        </ConditionalComponent>
                    </ConditionalComponent>
                    <ConditionalComponent
                        condition={!applicablePriorAuthorization}
                    >
                        <Stack gap="xs">
                            <Stack gap="xxxs">
                                <Flex gap="xs" justify="space-between">
                                    <Subtitle2>{lastActivityStatus}</Subtitle2>
                                    {hasOrderDate ? (
                                        <Body2>
                                            {formatToLocalizedDate(
                                                new Date(
                                                    prescriberOrder.latestSelectedPriceQuoteTimestamp!
                                                ),
                                                { dateStyle: 'medium' },
                                                i18n.language
                                            )}
                                        </Body2>
                                    ) : null}
                                </Flex>
                                <Body1>{sentToPharmacyName}</Body1>
                            </Stack>
                            <ConditionalComponent
                                condition={!isPrescriberOrderCancelled}
                            >
                                <Box>
                                    <TextButton
                                        RightIcon={IconChevronRight}
                                        onClick={navigateToOrderConfirmation}
                                    >
                                        {isRouted === false && showInProgress
                                            ? t('orderDetails.viewSendRequest')
                                            : t('orderDetails.viewOrder')}
                                    </TextButton>
                                </Box>
                            </ConditionalComponent>
                        </Stack>
                    </ConditionalComponent>
                </Stack>
            </OutlinedCard>
        </ConditionalComponent>
    );
};
