import {
    Anchor,
    Body1,
    Button,
    Flex,
    IconCall,
    IconControlledSubstance,
    Modal,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { ConditionalComponent } from '../../utility/ConditionalComponent';

type ControlledSubstanceModalProps = {
    prescriber?: string;
    prescriberNumber?: string | null;
    provider?: string;
    providerAddress?: string | null;
    opened: boolean;
    onClose: () => void;
    handleContinue: () => void;
    hasInstantRouting: boolean;
};

export const ControlledSubstanceModal = ({
    prescriber,
    prescriberNumber,
    provider,
    providerAddress,
    opened,
    onClose,
    handleContinue,
    hasInstantRouting,
}: ControlledSubstanceModalProps) => {
    const { t } = useTranslation();

    const hasPrescriberInfo = prescriber && prescriberNumber;
    const hasProviderInfo = provider && providerAddress;

    return (
        <Modal
            title={t('controlledSubstance.modal.header')}
            TitleIcon={<IconControlledSubstance color="gray" />}
            opened={opened}
            onClose={onClose}
        >
            <Stack gap="lg">
                <Stack gap="sm">
                    <Subtitle2>
                        {t(
                            'controlledSubstance.modal.sendingPrescription.part1'
                        )}
                    </Subtitle2>
                    <Body1>
                        {hasInstantRouting
                            ? t(
                                  'controlledSubstance.modal.sendingPrescription.part2'
                              )
                            : t(
                                  'controlledSubstance.modal.sendingPrescription.part3'
                              )}
                    </Body1>
                    <ConditionalComponent condition={!!hasProviderInfo}>
                        <Stack gap="xxs">
                            <Subtitle2>{provider}</Subtitle2>
                            <Body1>{providerAddress}</Body1>
                        </Stack>
                    </ConditionalComponent>
                    <ConditionalComponent condition={!!hasPrescriberInfo}>
                        <Stack gap="xxs">
                            <Subtitle2>{prescriber}</Subtitle2>
                            <Anchor
                                textDecoration="none"
                                href={`tel:${prescriberNumber}`}
                            >
                                <Flex gap="xxs">
                                    <IconCall />
                                    <Subtitle2 color="primary">
                                        {t(
                                            'controlledSubstance.modal.callProvider'
                                        )}
                                    </Subtitle2>
                                </Flex>
                            </Anchor>
                        </Stack>
                    </ConditionalComponent>
                </Stack>
                <Button variant="outline" onClick={handleContinue}>
                    {t('controlledSubstance.modal.continueToFill')}
                </Button>
            </Stack>
        </Modal>
    );
};
