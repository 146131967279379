import { Body1, Body2, Box, Flex, Subtitle2 } from '@phx/design-system';
import { MedFormIcon } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DrugDetails from '../../../components/common/DrugDetails';
import { getFragment } from '../../../graphql';
import { useFlags } from '../../../hooks/use-flags';
import i18n from '../../../i18n';
import { formatToLocalizedDate, titleCase } from '../../../util';
import {
    getMedicationDescription,
    getOrderQuantity,
    getPrescribedProductFragment,
    getWrittenDate,
} from '../../../util/cabinet/prescriber-orders';

import type { Prescription } from './HistorySearch';

type HistorySearchItemProps = {
    item: Prescription;
};

export const HistorySearchItem = ({ item }: HistorySearchItemProps) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const product = getFragment(getPrescribedProductFragment(item));
    const description = getMedicationDescription(item);
    const patient = getFragment(item.patient);

    const isDigitallyReceived =
        item?.__typename === 'DigitallyReceivedPrescriberOrder';

    const rawDate = getWrittenDate(item);

    const route = isDigitallyReceived ? `/orders/${item.drxId}/details` : '';
    const linkTo =
        isDigitallyReceived && item.drxId
            ? () => navigate(route, { state: { prev: 'history' } })
            : undefined;

    const date = rawDate
        ? formatToLocalizedDate(
              new Date(rawDate),
              { dateStyle: 'medium' },
              i18n.language
          )
        : null;

    const controlledSubstanceCode = isDigitallyReceived
        ? getFragment(item.authorizedMedication).controlledSubstanceCode
        : null;

    // Temporary until VELA integration
    const isInstantRouting = false;

    const displayProductNameOnly =
        drugDetailsSplitEnabled ||
        (!isInstantRouting && controlledSubstanceCode);

    return (
        <Flex
            gap="xs"
            justify="space-between"
            align="start"
            data-testid="prescription-history-block"
        >
            <Flex gap="xs">
                <MedFormIcon form={product?.formValue} />
                <Box
                    style={{ cursor: linkTo ? 'pointer' : '' }}
                    onClick={linkTo}
                    data-testid="prescription-history-block-view-order-button"
                >
                    <Subtitle2 data-testid="prescription-history-block-drug-description">
                        {displayProductNameOnly
                            ? titleCase(product?.name)
                            : description}
                    </Subtitle2>
                    {drugDetailsSplitEnabled && product ? (
                        <Box style={{ marginLeft: '10px' }}>
                            <DrugDetails
                                product={product}
                                quantity={getOrderQuantity(item)}
                            />
                        </Box>
                    ) : null}
                    <Body1 data-testid="prescription-history-block-patient">
                        {t('cabinet.medicationOrder.forName', {
                            name: patient.fullName,
                        })}
                    </Body1>
                </Box>
            </Flex>
            {date ? <Body2 noWrap>{date}</Body2> : null}
        </Flex>
    );
};
