import { createFormContext } from '@phx/design-system/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type BasicDetails = {
    day: string;
    month: string;
    year: string;
};

export const useDobValidation = <T extends BasicDetails>(
    form: ReturnType<ReturnType<typeof createFormContext<T>>[1]>
) => {
    const { t } = useTranslation();

    useEffect(() => {
        const day = Number.parseInt(form.values.day, 10);
        const month = Number.parseInt(form.values.month, 10);
        const year = Number.parseInt(form.values.year, 10);
        if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) {
            form.setFieldError('dob', t('common.formErrors.dob.required'));

            return;
        }

        if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900) {
            form.setFieldError('dob', t('common.formErrors.dob.invalid'));

            return;
        }

        const today = new Date();
        const minimumAge = new Date(
            today.getFullYear() - 13,
            today.getMonth(),
            today.getDate()
        );
        const dob = new Date(
            Number(year),
            Number(month) - 1,
            Number(day)
        ).getTime();

        if (today.getTime() - dob <= 0) {
            form.setFieldError('dob', t('common.formErrors.dob.future'));

            return;
        }

        if (minimumAge.getTime() - dob <= 0) {
            form.setFieldError('dob', t('common.formErrors.dob.tooYoung'));

            return;
        }

        form.clearFieldError('dob');
    }, [form.values.day, form.values.month, form.values.year]);
};
