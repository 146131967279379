import type { OfferFragment } from '../../../types';

import { MailOrderPricing } from './MailOrderPricing';
import { PickupPricing } from './PickupPricing';

type PharmacyTypes = 'MailOrderProvider' | 'PharmacyProvider';
const PricingByType: Record<
    PharmacyTypes,
    ({ offer }: { offer: OfferFragment }) => JSX.Element | null
> = {
    MailOrderProvider: MailOrderPricing,
    PharmacyProvider: PickupPricing,
};

export const Pricing = ({ offer }: { offer: OfferFragment }) => {
    if (!offer.pharmacyProvider.__typename) {
        return null;
    }

    const Component = PricingByType[offer.pharmacyProvider.__typename];
    return <Component offer={offer} />;
};
