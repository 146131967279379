import { useAuthContext } from '@phx/auth/client';
import {
    Button,
    Cover,
    Flex,
    Heading1,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProviderOffersInfoFragment } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import {
    filterByFavorites,
    filterByInstantRouting,
    filterByPricing,
} from '../../util/offers/filter';
import {
    type SortOption,
    sortOptions,
    sortProviderOffers,
} from '../../util/offers/sort';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import FilterCheckboxGroup from './FilterCheckboxGroup';
import FilterRadioGroup from './FilterRadioGroup';

type FilterCoverProps = {
    isOpen: boolean;
    onClose: () => void;
    pickUp?: boolean;
    providerOffers: ProviderOffersInfoFragment[];
    onSortedOffersChange?: (sortedOffers: ProviderOffersInfoFragment[]) => void;
    onActiveFiltersChange?: (activeFiltersCount: number) => void;
};

export const FilterCover = ({
    isOpen,
    onClose,
    pickUp,
    providerOffers,
    onSortedOffersChange,
    onActiveFiltersChange,
}: FilterCoverProps) => {
    const { t } = useTranslation();

    const { isAuthenticated } = useAuthContext();
    const { patient } = usePatientContext();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite } = favoritePharmacies;

    // Temporary until VELA integration
    const hasInstantRouting = false;

    const [savedFilters, setSavedFilters] = useState<{
        withInsurance: boolean;
        smartPrice: boolean;
        favorite: boolean;
        instantRouting?: boolean;
        sortBy: SortOption;
    }>({
        withInsurance: false,
        smartPrice: false,
        favorite: false,
        instantRouting: hasInstantRouting ? false : undefined,
        sortBy: pickUp ? sortOptions.distance : sortOptions.price,
    });

    const [currentFilters, setCurrentFilters] = useState(() => ({
        ...savedFilters,
        ...(hasInstantRouting
            ? { instantRouting: savedFilters.instantRouting }
            : {}),
    }));

    const title = pickUp
        ? t('providerOffers.filter.pickUp')
        : t('providerOffers.filter.delivery');

    const coverages = patient?.displayableCoverages ?? [];

    const hasPhxBenefitsPlan =
        coverages.filter(
            (coverage) => coverage.__typename === 'PhxBenefitsPlan'
        ).length > 0;

    const sortByOptions = useMemo(() => {
        if (pickUp) {
            return [
                {
                    value: sortOptions.distance,
                    label: t('filterAndSort.radio.distance'),
                },
                {
                    value: sortOptions.price,
                    label: t('filterAndSort.radio.price'),
                },
                {
                    value: sortOptions.planPays,
                    label: t('filterAndSort.radio.planPays'),
                },
            ];
        }

        return [
            { value: sortOptions.price, label: t('filterAndSort.radio.price') },
            {
                value: sortOptions.deliveryTime,
                label: t('filterAndSort.radio.deliveryTime'),
            },
            {
                value: sortOptions.planPays,
                label: t('filterAndSort.radio.planPays'),
            },
        ];
    }, [hasPhxBenefitsPlan, isAuthenticated, pickUp]);

    useEffect(() => {
        if (!isOpen) {
            // Reset temporary filters to previously applied filters when the cover is closed without calling handleSubmit
            setCurrentFilters(savedFilters);
        }
    }, [isOpen, savedFilters]);

    const handleReset = useCallback(() => {
        setCurrentFilters({
            withInsurance: false,
            smartPrice: false,
            favorite: false,
            instantRouting: currentFilters.instantRouting ? false : undefined,
            sortBy: sortByOptions[0].value,
        });
    }, [sortByOptions]);

    const sortedProviderOffers = useMemo(() => {
        let filteredOffers = providerOffers;
        filteredOffers = filterByPricing(filteredOffers, currentFilters);
        if (currentFilters.favorite) {
            filteredOffers = filterByFavorites(filteredOffers, checkIsFavorite);
        }
        if (currentFilters.instantRouting) {
            filteredOffers = filterByInstantRouting(filteredOffers); //TODO: instant routing filtering
        }
        return sortProviderOffers(filteredOffers, currentFilters.sortBy);
    }, [providerOffers, currentFilters, checkIsFavorite]);

    const activeFilterCount = useMemo(() => {
        let count = 0;

        if (currentFilters.sortBy !== sortByOptions[0].value) count++;
        if (currentFilters.withInsurance || currentFilters.smartPrice) count++;
        if (currentFilters.favorite) count++;
        if (currentFilters.instantRouting) count++;

        return count;
    }, [currentFilters, sortByOptions]);

    const handleSubmit = useCallback(() => {
        setSavedFilters(currentFilters);
        if (onSortedOffersChange) {
            onSortedOffersChange(sortedProviderOffers);
        }
        if (onActiveFiltersChange) {
            onActiveFiltersChange(activeFilterCount);
        }
        onClose();
    }, [
        currentFilters,
        sortedProviderOffers,
        onSortedOffersChange,
        onActiveFiltersChange,
        activeFilterCount,
        onClose,
    ]);

    const toggleWithInsurance = () =>
        setCurrentFilters((prev) => ({
            ...prev,
            withInsurance: !prev.withInsurance,
        }));
    const toggleSmartPrice = () =>
        setCurrentFilters((prev) => ({
            ...prev,
            smartPrice: !prev.smartPrice,
        }));
    const toggleFavorite = () =>
        setCurrentFilters((prev) => ({ ...prev, favorite: !prev.favorite }));
    const toggleInstantRouting = () => {
        if (hasInstantRouting) {
            setCurrentFilters((prev) => ({
                ...prev,
                instantRouting: !prev.instantRouting,
            }));
        }
    };

    return (
        <Cover visible={isOpen}>
            <Stack flex="1">
                <Flex justify="space-between" align="center">
                    <Heading1>
                        {`${title} ${t('providerOffers.filter.filters')}`}
                    </Heading1>
                    <TextButton
                        disabled={activeFilterCount === 0}
                        onClick={handleReset}
                    >
                        {t('filterAndSort.reset')}
                    </TextButton>
                </Flex>
                <Stack gap="xxl">
                    <FilterRadioGroup
                        title={t('filterAndSort.subtitle.sort')}
                        options={sortByOptions}
                        selection={currentFilters.sortBy}
                        onChange={(value) =>
                            setCurrentFilters((prev) => ({
                                ...prev,
                                sortBy: value as SortOption,
                            }))
                        }
                    />
                    <ConditionalComponent condition={isAuthenticated}>
                        <FilterCheckboxGroup
                            withInsurance={currentFilters.withInsurance}
                            toggleWithInsurance={toggleWithInsurance}
                            smartPrice={currentFilters.smartPrice}
                            toggleSmartPrice={toggleSmartPrice}
                            favorite={currentFilters.favorite}
                            instantRouting={
                                hasInstantRouting
                                    ? currentFilters.instantRouting
                                    : undefined
                            }
                            toggleFavorite={toggleFavorite}
                            toggleInstantRouting={toggleInstantRouting}
                            hasPhxBenefitsPlan={hasPhxBenefitsPlan}
                        />
                    </ConditionalComponent>
                </Stack>
                <Button onClick={handleSubmit}>
                    {sortedProviderOffers.length
                        ? t('common.cover.setFilters', {
                              count: sortedProviderOffers.length,
                          })
                        : t('common.cover.noPharmaciesFound')}
                </Button>
            </Stack>
        </Cover>
    );
};
