import { getFragment } from '../graphql';
import {
    type GetBasicPrescriberOrdersByPatientQuery,
    type GetBasicPrescriberOrdersQuery,
    PrescriberOrderStatus,
    PriorAuthorizationStatus,
} from '../graphql/generated/graphql';
import type { BasicPrescriberOrderFragment } from '../util/cabinet/prescriber-orders';

export type PrescriberOrdersFilter =
    | 'all'
    | 'new-all'
    | 'new-by-patient'
    | 'unclaimed';

export type UsePrescriberOrdersProps = {
    patient:
        | NonNullable<GetBasicPrescriberOrdersQuery['patient']>
        | NonNullable<GetBasicPrescriberOrdersByPatientQuery['patient']>;
    patientId: string;
    primaryPatientId: string;
    filter?: PrescriberOrdersFilter;
};

export const isNewOrder = (order: BasicPrescriberOrderFragment) => {
    // TODO: Temp solution to get incompleted/pending Prior Auth's with status "SENT_TO_PHARMACY" to appear in medcab
    const validPriorAuthStatuses = [
        PriorAuthorizationStatus.Appealed,
        PriorAuthorizationStatus.InReview,
        PriorAuthorizationStatus.New,
        PriorAuthorizationStatus.Received,
        PriorAuthorizationStatus.Redetermination,
    ];

    const priorAuthorizationStatus =
        order.__typename === 'DigitallyReceivedPrescriberOrder' &&
        getFragment(order.applicablePriorAuthorization)?.status;

    return (
        ('status' in order && order.status === PrescriberOrderStatus.New) ||
        order.__typename === 'UnclaimedPrescriberOrder' ||
        (priorAuthorizationStatus &&
            validPriorAuthStatuses.includes(priorAuthorizationStatus))
    );
};

export const usePrescriberOrders = ({
    patient,
    patientId,
    primaryPatientId,
    filter = 'all',
}: UsePrescriberOrdersProps) => {
    const isPrimaryPatient = patientId === primaryPatientId;
    const hasUnclaimedConnection =
        'unclaimedPrescriberOrdersConnection' in patient;
    const hasConsentedConnection = 'consentedPatientsConnection' in patient;

    const medications: BasicPrescriberOrderFragment[] = [];

    // Only show unclaimed prescriptions for the primary patient
    if (isPrimaryPatient && hasUnclaimedConnection) {
        patient.unclaimedPrescriberOrdersConnection.edges.forEach((edge) => {
            if (edge.node.__typename) {
                medications.unshift(getFragment(edge.node));
            }
        });
    }

    if (filter === 'unclaimed') return medications;

    patient.prescriberOrdersConnection.edges.forEach((edge) => {
        medications.push(getFragment(getFragment(edge.node)));
    });

    if (isPrimaryPatient && hasConsentedConnection) {
        patient.consentedPatientsConnection.edges.forEach((patientEdge) => {
            patientEdge.node.prescriberOrdersConnection.edges.forEach(
                (edge) => {
                    medications.push(getFragment(getFragment(edge.node)));
                }
            );
        });
    }

    if (filter === 'all') return medications;

    const newMeds = medications.filter(isNewOrder);

    if (filter === 'new-by-patient') {
        return newMeds.filter(
            (m) => 'status' in m && m.patient.id === patientId
        );
    }
    return newMeds;
};
