import {
    ApolloClient,
    type ApolloLink,
    ApolloProvider,
    type DefaultContext,
    type DocumentNode,
    InMemoryCache,
    type PossibleTypesMap,
    type TypePolicies,
    createHttpLink,
    defaultDataIdFromObject,
    from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getOrRefreshToken } from '@phx/auth/client';
import { getOverriddenFlagsFromStorage } from '@phx/feature-flags/client';
import { useTelemetryContext } from '@phx/instrumentation/react';
import {
    type SessionDetails,
    type TelemetryProviderInstance,
} from '@phx/instrumentation/web';
import { type PropsWithChildren as PWC, useMemo } from 'react';

import { graphDocuments } from '../graphql/client/helpers/documents';
import { MyPhxTypePolicies } from '../graphql/policies/type-policies';

import { type AppConfigCtx, useAppConfig } from './AppConfigProvider';
import { useErrorSessionContext } from './ErrorSessionProvider';

type GraphQLCtx = {
    routerAddress: string;
    typePolicies: TypePolicies;
    localSchema?: DocumentNode[];
    possibleTypes?: PossibleTypesMap;
};

const authClientId = import.meta.env.VITE_AUTH_CLIENT_ID;
const authTokenUrl = import.meta.env.VITE_AUTH_TOKEN_URL;
const isProduction = import.meta.env.VITE_ENV.toLowerCase() === 'production';

const createClient = (
    context: GraphQLCtx,
    link: ApolloLink,
    env: AppConfigCtx['env']
) => {
    const {
        routerAddress: uri,
        typePolicies,
        localSchema,
        possibleTypes,
    } = context;

    const apolloClient = new ApolloClient({
        connectToDevTools: env === 'development',
        uri,
        defaultOptions: {
            query: {
                errorPolicy: 'all',
            },
        },
        cache: new InMemoryCache({
            typePolicies,
            possibleTypes,
            dataIdFromObject: (object) => {
                const dataIds: Record<string, string> = {
                    UnclaimedPrescriberOrder: `UnclaimedPrescriberOrder:${object.drxId}`,
                    DigitallyReceivedPrescriberOrder: `PrescriberOrder:${object.drxId}`,
                    ClaimGeneratedPrescriberOrder: `PrescriberOrder:${object.id}`,
                    ChainPharmacy: `Location:${object.id}`,
                    Pharmacy: `Location:${object.id}`,
                    InsuranceOffer: `Offer:${object.id}`,
                    CashOffer: `Offer:${object.id}`,
                };

                const dataId = object.__typename
                    ? dataIds[object.__typename]
                    : undefined;

                return dataId ?? defaultDataIdFromObject(object);
            },
        }),
        link,
        typeDefs: localSchema,
    });

    return apolloClient;
};

const makeApolloClient = (
    config: AppConfigCtx,
    setErrorContext: (value: SessionDetails) => void,
    telemetryInstance: TelemetryProviderInstance
) => {
    const { routers, selectedRouter } = config.apollo;
    const routerAddress = routers[selectedRouter];
    const authLink = setContext(async (_req, { headers }: DefaultContext) => {
        const accessToken = await getOrRefreshToken(authClientId, authTokenUrl);
        const overriddenFlags = getOverriddenFlagsFromStorage();
        const customHeaders: Record<string, string> = { ...headers };

        if (accessToken) {
            customHeaders.authorization = `Bearer ${accessToken}`;
        }

        if (!isProduction) {
            customHeaders['x-feature-flags'] = JSON.stringify(overriddenFlags);
        }
        return {
            headers: customHeaders,
        };
    });

    const errorLink = onError(
        ({ graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
                const errors: { [key: string]: unknown } = {};

                graphQLErrors.forEach((error, index) => {
                    const {
                        message,
                        locations,
                        path,
                        originalError,
                        extensions,
                    } = error;
                    errors[index.toString()] = {
                        message,
                        locations,
                        path,
                        originalError,
                        extensions,
                    };
                });

                setErrorContext(telemetryInstance.getSessionDetails());

                telemetryInstance.logError({
                    exception: new Error(
                        `Apollo Client encountered Graphql errors on ${operation.operationName}`
                    ),
                    source: `Apollo Client: ${operation.operationName}`,
                    properties: errors,
                });
            }

            if (networkError) {
                telemetryInstance.logError({
                    ...networkError,
                    source: `Apollo Client: ${operation.operationName}`,
                });
                // This will retry only once. Additional errors will not be passed to onError
                return forward(operation);
            }
        }
    );

    const httpLink = createHttpLink({
        uri: routerAddress,
    });

    return createClient(
        {
            routerAddress,
            typePolicies: { ...MyPhxTypePolicies },
            localSchema: graphDocuments,
            possibleTypes: {
                Location: ['ChainPharmacy', 'Pharmacy'],
                Plan: ['PhxBenefitsPlan', 'SmartpricePlan'],
                PrescriberOrder: [
                    'ClaimGeneratedPrescriberOrder',
                    'DigitallyReceivedPrescriberOrder',
                ],
                Offer: ['CashOffer', 'InsuranceOffer'],
                Recommendation: [
                    'SavingsRecommendation',
                    'UnknownRecommendation',
                ],
                Provider: [
                    'PharmacyProvider',
                    'ChainProvider',
                    'MailOrderProvider',
                ],
                IndividualProvider: ['MailOrderProvider', 'PharmacyProvider'],
            },
        },
        from([errorLink, authLink, httpLink]),
        config.env
    );
};

export const GraphQLProvider = ({ children }: PWC) => {
    const { telemetryInstance } = useTelemetryContext();
    const config = useAppConfig();
    const { setSessionDetails } = useErrorSessionContext();

    const { routers, selectedRouter } = config.apollo;
    const routerAddress = routers[selectedRouter];

    const client = useMemo(
        () => makeApolloClient(config, setSessionDetails, telemetryInstance),
        [routerAddress]
    );

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
