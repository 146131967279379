import {
    Body1,
    Button,
    Heading1,
    HorizontalDivider,
    OutlinedCard,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type { ControlledSubstanceCode } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import type { OfferFragment } from '../../types';
import { getAbsoluteRoute } from '../../util';
import { Pricing } from '../common/pricing/Pricing';
import { PharmacyProviderDetails } from '../preview-details/components/PharmacyProviderDetails';
import { ShowPlanCard } from '../shopping/ShowPlanCard';

export type PrescriptionOverViewProps = {
    providerOffersId: string;
    offer: OfferFragment;
    header?: React.ReactNode;
    controlledSubstanceCode?: ControlledSubstanceCode | null;
};

export const PrescriptionOverview = ({
    offer,
    header,
    providerOffersId,
    controlledSubstanceCode,
}: PrescriptionOverViewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const patient = getFragment(offer.patient);
    const provider = getFragment(offer.pharmacyProvider);

    const { rxTransferEnabled } = useFlags();

    let planCardText = t('transfer.planCardPickup');
    if (provider.__typename === 'MailOrderProvider') {
        planCardText = provider.url
            ? t('transfer.planCardWebsite')
            : t('transfer.planCardNoWebsite');
    }

    return (
        <Stack gap="md">
            {header && header}
            <HorizontalDivider variant="full" />

            <Heading1>{t('transfer.overview')}</Heading1>
            <Pricing offer={offer} />

            <PharmacyProviderDetails offer={offer} />
            {patient && (
                <ShowPlanCard
                    plan={offer.plan}
                    patient={patient}
                    description={planCardText}
                    heading={t('transfer.planCardHeading')}
                />
            )}

            {rxTransferEnabled && (
                <OutlinedCard
                    header={
                        <Subtitle1>{t('transfer.atAnotherPharmacy')}</Subtitle1>
                    }
                >
                    {controlledSubstanceCode ? (
                        <Body1>{t('transfer.controlledSubstances')}</Body1>
                    ) : (
                        <Stack gap="sm">
                            <Body1>{t('transfer.weCanHelp')}</Body1>
                            <Button
                                variant="outline"
                                onClick={() => {
                                    navigate(
                                        getAbsoluteRoute(
                                            'transfer.location.root',
                                            {
                                                offerId: offer.id,
                                                providerOffersId,
                                            }
                                        )
                                    );
                                }}
                            >
                                {t('transfer.transferButton')}
                            </Button>
                        </Stack>
                    )}
                </OutlinedCard>
            )}

            <OutlinedCard
                header={<Subtitle1>{t('transfer.newScript')}</Subtitle1>}
            >
                <Stack gap="sm">
                    <Body1>{t('transfer.askYourDoctor')}</Body1>
                </Stack>
            </OutlinedCard>
        </Stack>
    );
};
