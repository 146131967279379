import {
    Body1,
    Flex,
    IconButton,
    IconControlledSubstance,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import { getAbsoluteRoute } from '../../../util';
import { RxForUser } from '../../cabinet/rx-for-user/RxForUser';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

import type { PatientProductDetailsProps } from './PatientProductDetails';

export const PatientProductDetailsHeader = ({
    patientName,
    productDescription,
    quantity,
    prescriberOrder,
}: PatientProductDetailsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const controlledSubstanceCode = getFragment(
        prescriberOrder?.authorizedMedication
    )?.controlledSubstanceCode;

    return (
        <Stack gap="xxs">
            {patientName ? (
                <RxForUser patient={{ fullName: patientName }} />
            ) : null}
            <Flex gap="xxs">
                <Subtitle2 data-testid="prescriber-order-product-details-header">
                    {productDescription}
                </Subtitle2>
                {controlledSubstanceCode ? (
                    <IconButton
                        Icon={IconControlledSubstance}
                        withStyles={false}
                        onClick={() =>
                            navigate(getAbsoluteRoute('helpCenter.medications'))
                        }
                    />
                ) : null}
            </Flex>
            <ConditionalComponent condition={!!quantity}>
                <Body1>
                    {t('order.prescribedQuantity', {
                        quantity,
                    })}
                </Body1>
            </ConditionalComponent>
        </Stack>
    );
};
