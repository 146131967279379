import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';
import type { OfferFragment } from '../../types';

export const isPriceableOffer = (
    offer?: OfferFragment | null
): offer is CashOfferInfoFragment | InsuranceOfferInfoFragment => {
    if (!offer) {
        return false;
    }

    return (
        offer.__typename === 'CashOffer' ||
        offer?.__typename === 'InsuranceOffer'
    );
};
