import { getFragment } from '../../graphql';
import type { OfferFragment } from '../../types';

export const getOffersByProviderId = (
    offers: Array<OfferFragment>,
    providerId: string
) =>
    offers.filter((offer) => {
        const provider = getFragment(offer.pharmacyProvider);
        return provider.id === providerId;
    });
