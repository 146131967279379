import { Anchor, Body1, Box } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans } from 'react-i18next';

import { PriorAuthorizationStatus } from '../../../../graphql/generated/graphql';
import { DecisionDetailsModal } from '../DecisionDetailsModal';
import { getStatusTranslation } from '../PriorAuthStatusDescriptions';

export const RedeterminationDescription = ({
    openDecisionLetter,
    rationale,
    trackingNumber,
}: {
    openDecisionLetter?: () => Window | null;
    rationale?: string | null;
    trackingNumber?: string | null;
}) => {
    const [opened, { toggle }] = useDisclosure();

    return (
        <Box>
            <Body1>
                <Trans
                    i18nKey="priorAuthorization.statusPage.redetermination.description"
                    components={{
                        1: <Anchor onClick={toggle} />,
                    }}
                />
            </Body1>
            <DecisionDetailsModal
                isOpen={opened}
                toggle={toggle}
                priorAuthStatus={getStatusTranslation(
                    PriorAuthorizationStatus.Denied
                )}
                tracking={trackingNumber}
                rationale={rationale}
                onOpenDecisionLetter={openDecisionLetter}
            />
        </Box>
    );
};
