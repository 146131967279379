import { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';

import { getFragment, getFragments } from '../graphql';
import type { DrugFamilyInfoFragment } from '../graphql/generated/graphql';
import { getTotalQuantityDescription } from '../util';

export const useDrugConfigSearchParams = (
    drugFamily: DrugFamilyInfoFragment
) => {
    const product = getFragment(drugFamily.defaultProduct);
    const defaultQuantity = product.modeQuantity ?? 30;
    const defaultDaysSupply = product.modeDaysSupply ?? 30;

    const [params, setParams] = useState<string>(
        createSearchParams({
            familyId: drugFamily.id,
            quantity: defaultQuantity.toString(),
            daysSupply: defaultDaysSupply.toString(),
        }).toString()
    );
    useEffect(() => {
        const productInfo = getFragment(drugFamily.defaultProduct);

        const form = getFragments(drugFamily.forms).find(
            (form) => form.defaultNdc === productInfo.id
        );
        const drugPackage = getFragments(form?.packages)?.find(
            (formPackage) => formPackage.id === productInfo.id
        );

        /**
         * If this is not a simple individual quantity use the quantity for a single package
         * Set search params for following page to use.
         */
        if (
            !(
                drugPackage?.packageRelevance.isSimpleQuantity &&
                !drugPackage?.packageRelevance.isPackageRelevant
            )
        ) {
            const matchingPackage =
                form &&
                getFragments(form.packages).find(
                    (packageInfo) => packageInfo.id === drugFamily.defaultNdc
                );

            const total =
                matchingPackage &&
                getTotalQuantityDescription(1, form, matchingPackage).value;

            setParams(
                createSearchParams({
                    familyId: drugFamily.id,
                    qInput: '1',
                    quantity: total
                        ? total.toString()
                        : defaultQuantity.toString(),
                    daysSupply: defaultDaysSupply.toString(),
                }).toString()
            );
        }
    }, [drugFamily]);

    return params;
};
