import { Anchor, Body1 } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans, useTranslation } from 'react-i18next';

import { PriorAuthorizationStatus } from '../../../../graphql/generated/graphql';
import { DecisionDetailsModal } from '../DecisionDetailsModal';
import { getStatusTranslation } from '../PriorAuthStatusDescriptions';

export const AppealedDescription = ({
    openDecisionLetter,
    rationale,
    trackingNumber,
}: {
    openDecisionLetter?: () => Window | null;
    rationale?: string | null;
    trackingNumber?: string | null;
}) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure();

    return (
        <Body1>
            {t('priorAuthorization.statusPage.appealed.partOne')}{' '}
            {openDecisionLetter ? (
                <>
                    <Trans
                        i18nKey="priorAuthorization.statusPage.appealed.partTwo"
                        components={{
                            2: <Anchor onClick={toggle} />,
                        }}
                    />
                    <DecisionDetailsModal
                        isOpen={opened}
                        toggle={toggle}
                        priorAuthStatus={getStatusTranslation(
                            PriorAuthorizationStatus.Denied
                        )}
                        tracking={trackingNumber}
                        rationale={rationale}
                        onOpenDecisionLetter={openDecisionLetter}
                    />
                </>
            ) : (
                t(
                    'priorAuthorization.statusPage.appealed.partTwoNoDecisionLetter'
                )
            )}
        </Body1>
    );
};
