import { Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

export const NoPricing = () => {
    const { t } = useTranslation();

    return (
        <>
            <Stack gap="xxs">
                <Stack gap="xxxs">
                    <Subtitle2>{t('noPrice.content.card')}</Subtitle2>
                </Stack>
            </Stack>
        </>
    );
};
