import {
    Accordion,
    Body1,
    Breadcrumbs,
    Heading1,
    LoadingPlaceholder,
    Stack,
} from '@phx/design-system';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { useFlags } from '../../../../hooks/use-flags';
import { usePatientContext } from '../../../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../../../util';

import { useFaqTranslationRoutes } from './FaqTranslationRoutes';

type FaqSectionKey =
    | 'about'
    | 'employer'
    | 'settings'
    | 'costsAndSavings'
    | 'prescriptionManagement'
    | 'familyCare'
    | 'medications'
    | 'privacySecurity';

export type FaqProps = { section: FaqSectionKey };

const Faq = ({ section }: FaqProps) => {
    const { t } = useTranslation(['help-center-locales/help-center']);
    const routes = useFaqTranslationRoutes();
    const { patient } = usePatientContext();
    const { helpCenterMedicationEnabled } = useFlags();

    if (
        (!patient?.hasPbmPlan && section === 'employer') ||
        (!helpCenterMedicationEnabled && section === 'medications')
    )
        return <Navigate to={getAbsoluteRoute('error')} />;

    const location = useLocation();
    const defaultValue = location.state?.defaultValue;

    const query = new URLSearchParams(location.search).get('q');
    const queryIndex = query ? parseInt(query, 10) : null;

    const faqs = useMemo(() => {
        const faqObjects = t(`faq.${section}`, { returnObjects: true });

        if (!Array.isArray(faqObjects)) {
            return [];
        }

        return faqObjects.map((faq) => ({
            header: faq.question,
            key: faq.question,
            children: (
                <Stack>
                    <Body1>
                        <Trans components={routes}>{faq.answer}</Trans>
                    </Body1>
                    {faq.answer2 ? <Body1>{faq.answer2}</Body1> : null}
                    {faq.answer3 ? <Body1>{faq.answer3}</Body1> : null}
                </Stack>
            ),
        }));
    }, [t, section]);

    const breadcrumbItems = useMemo(
        () => [
            { title: t('helpCenter.breadcrumb.header'), href: '/help-center' },
            {
                title: t(`helpCenter.faq.${[section]}`),
                href: location.pathname,
            },
        ],
        [t, section, location.pathname]
    );

    const faqDefaultValue = useMemo(() => {
        if (!faqs.length) return undefined;
        return queryIndex ? faqs[queryIndex].header : defaultValue;
    }, [defaultValue, queryIndex, faqs]);

    if (!faqs.length) {
        return <LoadingPlaceholder rows={4} />;
    }

    return (
        <Stack>
            <Breadcrumbs items={breadcrumbItems} />
            <Heading1>
                {t(`helpCenter.faq.${[section]}`) === 'Medications'
                    ? 'Controlled substances'
                    : t(`helpCenter.faq.${[section]}`)}
            </Heading1>
            <Accordion defaultValue={faqDefaultValue} items={faqs} />
        </Stack>
    );
};

export default Faq;
