import { Navigate, Outlet, type RouteObject } from 'react-router-dom';

import { getAbsoluteRoute } from '../../../util';
import routes from '../../app-routes';

import { PrescriptionRedirect } from './PrescriptionRedirect';

const patientRoutes: RouteObject[] = [
    {
        path: routes.cabinet.patientId.root,
        element: <Outlet />,
        children: [
            {
                path: routes.cabinet.patientId.digitalRx.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate
                                to={getAbsoluteRoute('cabinet.root')}
                                replace
                            />
                        ),
                    },
                    {
                        path: routes.cabinet.patientId.digitalRx.rxId,
                        element: <PrescriptionRedirect />,
                    },
                ],
            },
        ],
    },
];

export default patientRoutes;
